<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img width="300" src="../../assets/img/logo.png" alt="" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="javascript:void();" class="active">1</a>
                        </li>
                        <li><a href="javascript:void();">2</a></li>
                        <li><a href="javascript:void();">3</a></li>
                        <li><a href="javascript:void();">4</a></li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="profile-pic-col">
                        <h3 class="mb-3">Let's Get Started!</h3>
                        <div class="form-group text-left">
                          <label>First Name</label>
                          <input
                            type="text"
                            name="firstname"
                            v-model="firstName"
                            class="form-control"
                            required
                          />
                        </div>
                        <div class="form-group text-left">
                          <label>Last Name</label>
                          <input
                            type="text"
                            name="lastname"
                            v-model="lastName"
                            class="form-control"
                            required
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <button
                          id="submitBtn"
                          type="submit"
                          class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span
                    >© 2025. StepAhead Health Inc. All Rights Reserved.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
        <!-- /Page Content -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
    };
  },

  async mounted() {
    this.showLoader(true);
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.showLoader(false);
  },

  methods: {
    ...mapActions(["saveUserDetails", "showLoader", "showAlert"]),

    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          first_name: this.firstName,
          last_name: this.lastName,
          current_registration_step: 2,
        });

        this.$router.push("/patient/step-2");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        this.showAlert({
          message: e.response.data.message,
          type: "danger",
          time: 4000,
        });
        console.error(e);
      }
    },
  },
};
</script>
