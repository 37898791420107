<template>
  <section class="section section-search">
    <div class="container-fluid">
      <div class="row">
        <div class="order-1 content-area col-md-7 order-md-1">
          <div class="banner-wrapper mr-3">
            <div class="banner-header text-center mb-2">
              <h3>Search Medicines</h3>
            </div>

            <hr />

            <!-- Search -->
            <div class="search-box mt-3">
              <div class="form-group search-info">
                <vue-select
                  class="form-control"
                  v-model="searchMedicine"
                  :options="medicineOptions"
                  searchable
                  clear-on-close
                  close-on-select
                  clear-on-select
                  :loading="loading"
                  @search:input="startSearching"
                  search-placeholder="Search Medicines"
                ></vue-select>
                <span class="form-text mb-4">Ex: Glipizide, Lipitor</span>
              </div>
              <button
                @click="addMedicineToList"
                class="btn btn-primary search-btn mt-0"
              >
                <i class="fas fa-plus"></i> <span>Add</span>
              </button>
            </div>
            <!-- /Search -->
          </div>
        </div>
        <div class="order-2 col-md-5 order-md-2 mt-3">
          <div class="card meds-list">
            <div class="card-header">
              <h3>RxRefill List</h3>
            </div>
            <ul
              class="list-group list-group-flush"
              style="height: 200px; overflow: auto"
            >
              <li
                class="list-group-item"
                style="font-size: 14px"
                v-for="(medicine, i) in selectedMedicines"
                :key="i"
              >
                <div class="row">
                  <div class="col-10">
                    {{ medicine.name }}
                  </div>
                  <div class="col-2">
                    <button
                      @click="removeMedicineFromList(medicine)"
                      class="btn btn-sm btn-light mr-2"
                      title="Remove Medicine"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>

            <div v-show="selectedMedicines.length !== 0" class="text-center">
              <button
                @click="selectMedicines"
                class="btn btn-info btn-block cont-btn"
              >
                Confirm & Proceed <i class="fa fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import mixin from "../../mixins/common";

export default {
  mixins: [mixin],
  data() {
    return {
      searchMedicine: "",
      medicineOptions: [],
      masterMedicineOptions: [],
      selectedMedicines: [],
      loading: false,
    };
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    selectMedicines() {
      if (this.selectedMedicines.length === 0) {
        this.showAlert({
          message: "Please select medicines for prescription",
          type: "danger",
          time: 4000,
        });
        return;
      }
      this.$emit("select", JSON.stringify(this.selectedMedicines));
    },

    removeMedicineFromList(medicine) {
      this.selectedMedicines = this.selectedMedicines.filter(
        (a) => a.name != medicine.name
      );
    },

    addMedicineToList() {
      if (this.searchMedicine === "") return;

      const med = this.masterMedicineOptions.find(
        (medicine) => medicine.name === this.searchMedicine
      );

      if (med)
        this.selectedMedicines.push({
          ...med,
          medicine_name: med.name,
          quantity: 0,
          refills: 0,
          supply_days: 0,
          directions: "",
        });

      this.searchMedicine = {};
      this.medicineOptions = [];
      this.masterMedicineOptions = [];
    },

    async startSearching(input) {
      try {
        if (
          input.target &&
          input.target.value &&
          input.target.value.length > 3
        ) {
          this.loading = true;
          const res = await this.searchMedicineByName(input.target.value);

          this.masterMedicineOptions = res;
          if (this.masterMedicineOptions.length > 0) {
            this.medicineOptions = this.masterMedicineOptions.map(
              (a) => a.name
            );
          }
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        this.showAlert({
          message: e,
          type: "danger",
          time: 4000,
        });
      }
    },

    selectPharmacy(pharmacyDetails) {
      this.$emit("select", JSON.stringify(pharmacyDetails));
    },
  },
};
</script>
<style scoped>
.cont-btn {
  position: absolute;
  bottom: 20px;
  left: 10px;
  width: 90%;
}

.meds-list {
  position: relative;
  height: 350px;
}

.section-search {
  min-height: 600px;
}

.search-box .search-info {
  flex: 0 0 92%;
  width: 92%;
}

.search-info .form-control {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .search-info .form-control {
    width: 110%;
  }
}
</style>
