<template>
  <!-- Home Banner -->
  <section
    class="section section-search"
    v-if="currentpath == 'index' || currentpath == 'index-page'"
  >
    <div class="container-fluid">
      <div class="banner-wrapper">
        <div class="banner-header text-center">
          <h1>
            {{ $t("homePage.bannerSection.bannerTitle1") }}
          </h1>
          <p>
            {{ $t("homePage.bannerSection.bannerTitle2") }}
          </p>
        </div>

        <div class="text-center">
          <p class="mt-5">
            {{ $t("homePage.bannerSection.bannerTitle3") }}
          </p>
        </div>
        <hr />
        <!-- Search -->
        <div class="search-box text-center mt-3">
          <div class="form-group search-info mb-2">
            <router-link to="/patient/register">
              <button class="btn btn-info btn-block">
                {{ $t("homePage.bannerSection.bannerPatientButton") }}
              </button>
            </router-link>
          </div>
          <div class="form-group search-location-home">
            <router-link to="/provider/register">
              <button class="btn btn-primary btn-block mb-2">
                {{ $t("homePage.bannerSection.bannerDoctorButton") }}
              </button>
            </router-link>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-12 text-center">
            <p>
              {{ $t("homePage.bannerSection.mobileAppTitle") }}
            </p>
          </div>
          <div class="col-12 text-center">
            <a href="https://apps.apple.com/us/app/id6444579218">
              <img
                src="../assets/img/appstore.svg"
                class="mb-5"
                style="width: 200px"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.stepaheadhealth.app&hl=en&gl=US"
            >
              <img
                src="../assets/img/playstore.svg"
                class="mb-5"
                style="width: 200px"
              />
            </a>
          </div>
        </div>
        <!-- /Search -->
      </div>
    </div>
  </section>
  <!-- /Home Banner -->
</template>

<script>
export default {
  computed: {
    currentpath() {
      return this.$route.name;
    },
  },
};
</script>
