<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img src="../../assets/img/logo.png" alt="" width="300" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/provider/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="/app/provider/step-2" class="active-done"
                            >2</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">3</a>
                        </li>
                        <li><a href="javascript:void();">4</a></li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                        <li><a href="javascript:void();">9</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <h3 class="my-4">License Information</h3>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-6">
                            <div class="form-group text-left">
                              <label>NPI ID</label>
                              <input
                                type="text"
                                name="address1"
                                class="form-control"
                                v-model="npiId"
                                required
                                disabled
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group text-left">
                              <label>License No.</label>
                              <input
                                type="text"
                                name="address2"
                                class="form-control"
                                v-model="licenseNo"
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div class="form-group text-left">
                          <label>Description</label>
                          <input
                            type="text"
                            name="address1"
                            class="form-control"
                            v-model="description"
                            required
                            disabled
                          />
                        </div>
                        <div class="form-group text-left">
                          <label>Enumeration Date</label>
                          <input
                            type="text"
                            name="address2"
                            class="form-control"
                            :value="new Date(certificationDate).toDateString()"
                            disabled
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-4">
                            <router-link to="/provider/step-2">
                              <a
                                href="javascript:void()"
                                class="btn btn-light btn-block btn-lg"
                              >
                                Back
                              </a>
                            </router-link>
                          </div>
                          <div class="col-8">
                            <button
                              type="submit"
                              class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span
                    >© 2025. StepAhead Health Inc. All Rights Reserved.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      npiId: "",
      licenseNo: "",
      description: "",
      state: "",
      certificationDate: "",
    };
  },
  async mounted() {
    this.showLoader(true);

    this.npiId = this.$store.getters.user.npi;
    this.licenseNo = this.$store.getters.user.license;
    this.description = this.$store.getters.user.description;
    this.state = this.$store.getters.user.state;
    this.certificationDate = this.$store.getters.user.enumeration_date;

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["saveUserDetails", "showLoader"]),

    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "provider",
          current_registration_step: 4,
        });

        this.$router.push("/provider/step-4");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },
  },
};
</script>
