<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img src="../../assets/img/logo.png" alt="" width="300" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done"
                            >2</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">3</a>
                        </li>
                        <li><a href="javascript:void();">4</a></li>
                        <li><a href="javascript:void();">5</a></li>
                        <li><a href="javascript:void();">6</a></li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="text-center mt-2">
                        <h4 class="mt-3">Personal Information</h4>
                      </div>
                      <div class="profile-pic-col text-left">
                        <div class="form-group">
                          <label>Date of Birth (MM/DD/YYYY)</label>
                          <Datepicker
                            :lowerLimit="dateLowerLimit"
                            :upperLimit="dateUpperLimit"
                            inputFormat="MM-dd-yyyy"
                            class="form-control"
                            v-model="dob"
                          />
                        </div>
                        <div class="form-group">
                          <label>Weight - In Pounds (LB)</label>
                          <div class="row">
                            <div class="col-12">
                              <input
                                type="number"
                                min="0"
                                max="800"
                                class="form-control"
                                name="weight"
                                v-model="weight"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Height - In Inch (IN)</label>
                          <div class="row">
                            <div class="col-12 text-center">
                              <input
                                type="number"
                                min="0"
                                max="500"
                                v-model="height"
                                class="form-control"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-6">
                            <a
                              href="javascript:void()"
                              class="btn btn-primary btn-block btn-lg login-btn"
                              @click="goBack"
                            >
                              Back
                            </a>
                          </div>
                          <div class="col-6">
                            <button
                              type="submit"
                              class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span
                    >© 2025. StepAhead Health Inc. All Rights Reserved.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Datepicker from "vue3-datepicker";
import commonMixin from "../../mixins/common";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      dob: new Date(),
      dateLowerLimit: new Date(1920, 0, 1),
      dateUpperLimit: new Date(),
      weight: 0,
      height: 0,
    };
  },
  components: {
    Datepicker,
  },
  mixins: [commonMixin],
  mounted() {
    this.dob = new Date(this.$store.getters.user.dob);
    this.weight = String(this.$store.getters.user.weight).split("-")[0];
    this.height = String(this.$store.getters.user.height).split("-")[0];
  },
  methods: {
    goBack() {
      this.$router.push("/patient/step-2");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),
    async saveStep() {
      try {
        this.showLoader(true);
        const formated = this.formatDate(this.dob);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          dob: formated,
          weight: this.weight,
          weight_unit: "LB",
          height: this.height,
          height_unit: "IN",
          current_registration_step: 4,
        });

        this.$router.push("/patient/step-4");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        Swal.fire("Ooops!", e.response.data, "error");
        console.error(e);
      }
    },
  },
};
</script>
