<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <!-- Main Banner -->
    <home-banner @goTo="goTo"></home-banner>

    <!-- Process section -->
    <process id="process"></process>

    <!-- Patient services -->
    <looking id="patient"></looking>

    <!-- Provider Services -->
    <features id="provider"></features>

    <!-- Mobile App section -->
    <section class="section home-tile-section responsive">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-5">
            <div class="section-header text-right mt-5 responsive">
              <h1 class="mt-3" style="center">
                {{ $t("homePage.mobileAppSection.title") }}
              </h1>
              <h4>
                {{ $t("homePage.mobileAppSection.description1") }} <br />{{
                  $t("homePage.mobileAppSection.description2")
                }}
              </h4>
              <hr />

              <a href="https://apps.apple.com/us/app/id6444579218">
                <img
                  src="../assets/img/appstore.svg"
                  class="img-fluid"
                  style="width: 200px"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.stepaheadhealth.app&hl=en&gl=US"
              >
                <img
                  src="../assets/img/playstore.svg"
                  class="img-fluid"
                  style="width: 200px"
                />
              </a>
            </div>
          </div>
          <div class="col-md-7 features-img text-center responsive">
            <img
              src="../assets/img/mobile-1.png"
              alt="Telemedicine app"
              class="img-fluid"
              width="700"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Select Sections-->
    <section class="patient-select">
      <div class="container">
        <div class="text-center mt-2 mb-5">
          <h1 style="color: white">
            {{ $t("homePage.letsGetStartedSection.title") }}
          </h1>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="select-box">
              <div class="d-flex align-items-center">
                <div class="image-holder">
                  <span>
                    <img
                      src="../assets/img/icons/doct-01.png"
                      alt="Telemedicine app"
                    />
                  </span>
                </div>
                <div>
                  <h2>
                    {{
                      $t(
                        "homePage.letsGetStartedSection.areYouAProviderSection.title"
                      )
                    }}
                  </h2>
                  <p>
                    {{
                      $t(
                        "homePage.letsGetStartedSection.areYouAProviderSection.description"
                      )
                    }}
                  </p>
                  <router-link
                    to="/provider/register"
                    class="book-now"
                    @click="moveUp"
                    >{{
                      $t(
                        "homePage.letsGetStartedSection.areYouAProviderSection.button"
                      )
                    }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="select-box theme">
              <div class="d-flex align-items-center">
                <div class="image-holder">
                  <span>
                    <img
                      src="../assets/img/icons/doct-02.png"
                      alt="Online doctor consultation."
                    />
                  </span>
                </div>
                <div>
                  <h2>
                    {{
                      $t(
                        "homePage.letsGetStartedSection.areYouAPatientSections.title"
                      )
                    }}
                  </h2>
                  <p>
                    {{
                      $t(
                        "homePage.letsGetStartedSection.areYouAPatientSections.description"
                      )
                    }}
                  </p>
                  <router-link
                    to="/patient/register"
                    class="book-now"
                    @click="moveUp"
                    >{{
                      $t(
                        "homePage.letsGetStartedSection.areYouAPatientSections.button"
                      )
                    }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Diseases section -->
    <Diseases></Diseases>
    <!-- Conditions Section -->
    <Medical_Conditions></Medical_Conditions>

    <!-- Medicines section -->
    <Medicines></Medicines>

    <!-- Faq section -->
    <FAQ></FAQ>

    <layout-footer class="footer"></layout-footer>
  </div>
</template>

<script>
import Medical_Conditions from "./Medical_Conditions.vue";

export default {
  mounted() {
    if (this.$store.getters.user) {
      switch (this.$store.getters.user.role) {
        case "patient":
          if (!this.$store.getters.user.isRegistrationComplete) {
            this.$router.push(
              `/patient/step-${this.$store.getters.user.current_registration_step}`
            );
          } else this.$router.push("/patient/index");
          break;
        case "doctor":
          this.$router.push("/provider/index");
          break;
      }
    }
  },
  methods: {
    goTo(refName) {
      var element = document.getElementById(refName);
      if (!element) return;
      var top = element.offsetTop;
      window.scrollTo(0, top - 50);
    },
    moveUp() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  components: { Medical_Conditions },
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {
  /* .footer {
    display: none;
  } */

  .responsive {
    text-align: center !important;
  }
}
</style>
