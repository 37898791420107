<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img src="../../assets/img/logo.png" alt="" width="300" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done"
                            >2</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-3" class="active-done"
                            >3</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-4" class="active-done"
                            >4</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-5" class="active-done"
                            >5</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">6</a>
                        </li>
                        <li><a href="javascript:void();">7</a></li>
                        <li><a href="javascript:void();">8</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="text-center mt-2">
                        <h4 class="mt-3 mb-3">Medical History</h4>
                      </div>
                      <div class="checklist-col pregnant-col">
                        <div class="form-group text-left">
                          <label
                            >Past Medical History <small>(Optional)</small>
                          </label>
                          <textarea
                            name="past"
                            class="form-control"
                            v-model="pastMedicalHistory"
                          ></textarea>
                        </div>
                        <div class="form-group text-left">
                          <label
                            >Social Medical History
                            <small>(Optional)</small></label
                          >
                          <textarea
                            name="social"
                            v-model="socialMedicalHistory"
                            class="form-control"
                          ></textarea>
                        </div>
                        <div class="form-group text-left">
                          <label
                            >Implant Medical History
                            <small>(Optional)</small></label
                          >
                          <textarea
                            name="implant"
                            v-model="implantMedicalHistory"
                            class="form-control"
                          ></textarea>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-6">
                            <a
                              href="javascript:void()"
                              class="btn btn-primary btn-block btn-lg login-btn"
                              @click="goBack"
                            >
                              Back
                            </a>
                          </div>
                          <div class="col-6">
                            <button
                              type="submit"
                              class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span
                    >© 2025. StepAhead Health Inc. All Rights Reserved.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      pastMedicalHistory: "",
      socialMedicalHistory: "",
      implantMedicalHistory: "",
    };
  },
  mounted() {
    this.pastMedicalHistory = this.$store.getters.user.medical_history;
    this.socialMedicalHistory = this.$store.getters.user.social_history;
    this.implantMedicalHistory = this.$store.getters.user.implant_history;
  },
  methods: {
    goBack() {
      this.$router.push("/patient/step-5");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),
    async saveStep() {
      try {
        this.showLoader(true);
        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          medical_history: this.pastMedicalHistory,
          social_history: this.socialMedicalHistory,
          implant_history: this.implantMedicalHistory,
          current_registration_step: 7,
        });

        this.$router.push("/patient/step-7");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },
  },
};
</script>
