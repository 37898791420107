<template>
  <div class="account-page">
    <div class="main-wrapper">
      <!-- Page Content -->
      <div class="content login-page pt-0">
        <div class="container-fluid">
          <!-- Register Content -->
          <div class="account-content">
            <div class="row align-items-center">
              <div class="login-right">
                <div class="inner-right-login">
                  <div class="login-header">
                    <div class="logo-icon">
                      <img src="../../assets/img/logo.png" alt="" width="300" />
                    </div>
                    <div class="step-list">
                      <ul>
                        <li>
                          <a href="/app/patient/step-1" class="active-done"
                            >1</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-2" class="active-done"
                            >2</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-3" class="active-done"
                            >3</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-4" class="active-done"
                            >4</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-5" class="active-done"
                            >5</a
                          >
                        </li>
                        <li>
                          <a href="/app/patient/step-6" class="active-done"
                            >6</a
                          >
                        </li>
                        <li>
                          <a href="javascript:void();" class="active">7</a>
                        </li>
                        <li><a href="javascript:void();">8</a></li>
                      </ul>
                    </div>
                    <form @submit.prevent="saveStep">
                      <div class="text-center mt-2">
                        <h4 class="mt-3">
                          Medical Pre-Conditions <small>(Optional)</small>
                        </h4>
                      </div>
                      <div class="checklist-col pregnant-col">
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">Heart Disease</span>
                          <label class="custom_check">
                            <input v-model="heartCheck" type="checkbox" />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">Hyper Tension</span>
                          <label class="custom_check">
                            <input
                              v-model="hyperTensionCheck"
                              type="checkbox"
                              value="Hyper Tension"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">Diabetes</span>
                          <label class="custom_check">
                            <input
                              v-model="diabetesCheck"
                              type="checkbox"
                              value="Diabetes"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">Tuberculosis</span>
                          <label class="custom_check">
                            <input
                              v-model="tbCheck"
                              type="checkbox"
                              value="Tuberculosis"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">Vision / Eye Problems</span>
                          <label class="custom_check">
                            <input
                              v-model="visionCheck"
                              type="checkbox"
                              value="Vision / Eye Problems"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">Asthma</span>
                          <label class="custom_check">
                            <input
                              v-model="asthmaCheck"
                              type="checkbox"
                              value="Asthma"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">AIDS / HIV</span>
                          <label class="custom_check">
                            <input
                              v-model="aidsCheck"
                              type="checkbox"
                              value="AIDS / HIV"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                        <div
                          class="remember-me-col d-flex justify-content-between"
                        >
                          <span class="mt-1">Cancer</span>
                          <label class="custom_check">
                            <input
                              v-model="cancerCheck"
                              type="checkbox"
                              value="Cancer"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-6">
                            <a
                              href="javascript:void()"
                              class="btn btn-primary btn-block btn-lg login-btn"
                              @click="goBack"
                            >
                              Back
                            </a>
                          </div>
                          <div class="col-6">
                            <button
                              type="submit"
                              class="btn btn-primary btn-block btn-lg login-btn step1_submit"
                            >
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="login-bottom-copyright">
                  <span
                    >© 2025. StepAhead Health Inc. All Rights Reserved.</span
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- /Register Content -->
        </div>
      </div>
      <!-- /Page Content -->
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      heartCheck: false,
      hyperTensionCheck: false,
      diabetesCheck: false,
      tbCheck: false,
      visionCheck: false,
      asthmaCheck: false,
      aidsCheck: false,
      cancerCheck: false,
      existingConditions: [],
    };
  },
  mounted() {
    if (this.$store.getters.user.existing_conditions !== null) {
      for (let i of this.$store.getters.user.existing_conditions) {
        if (i == "Heart Disease") this.heartCheck = true;
        if (i == "Hyper Tension") this.hyperTensionCheck = true;
        if (i == "Diabetes") this.diabetesCheck = true;
        if (i == "Tuberculosis") this.tbCheck = true;
        if (i == "Vision / Eye Problems") this.visionCheck = true;
        if (i == "Asthma") this.asthmaCheck = true;
        if (i == "AIDS / HIV") this.aidsCheck = true;
        if (i == "Cancer") this.cancerCheck = true;
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push("/patient/step-6");
    },
    ...mapActions(["saveUserDetails", "showLoader"]),
    async saveStep() {
      try {
        this.showLoader(true);

        this.existingConditions = [];
        if (this.heartCheck) this.existingConditions.push("Heart Disease");
        if (this.hyperTensionCheck)
          this.existingConditions.push("Hyper Tension");
        if (this.diabetesCheck) this.existingConditions.push("Diabetes");
        if (this.tbCheck) this.existingConditions.push("Tuberculosis");
        if (this.visionCheck)
          this.existingConditions.push("Vision / Eye Problems");
        if (this.asthmaCheck) this.existingConditions.push("Asthma");
        if (this.aidsCheck) this.existingConditions.push("AIDS / HIV");
        if (this.cancerCheck) this.existingConditions.push("Cancer");

        let medicalHistory = this.$store.getters.user.medicalHistory;

        const res = await this.saveUserDetails({
          id: this.$store.getters.user.id,
          role: "patient",
          existing_conditions: this.existingConditions.join(","),
          current_registration_step: 8,
        });

        this.$router.push("/patient/step-8");
        this.showLoader(false);
      } catch (e) {
        this.showLoader(false);
        console.error(e);
      }
    },
  },
};
</script>
