<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <!-- Breadcrumb -->
    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-12 col-12">
            <h2 class="breadcrumb-title">Billings & Payments</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-lg-8">
            <div class="card">
              <div class="card-body">
                <!-- Checkout Form -->
                <form>
                  <!-- Billing Information -->
                  <div class="info-widget">
                    <h4 class="card-title">Billing Information</h4>
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>First Name</label>
                          <input
                            class="form-control"
                            v-model="firstName"
                            type="text"
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Last Name</label>
                          <input
                            class="form-control"
                            v-model="lastName"
                            type="text"
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Email</label>
                          <input
                            class="form-control"
                            type="email"
                            v-model="email"
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Mobile Number</label>
                          <input
                            class="form-control"
                            type="text"
                            v-model="mobileNumber"
                            required
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Personal Information -->

                  <div class="payment-widget">
                    <h4 class="card-title">Payment Methods</h4>

                    <!-- Submit Section -->
                    <div class="submit-section mt-4">
                      <div id="paypal-button-container"></div>
                    </div>
                    <!-- /Submit Section -->
                  </div>
                </form>
                <!-- /Checkout Form -->
              </div>
            </div>
          </div>

          <div class="col-md-5 col-lg-4 theiaStickySidebar">
            <div class="stickyside">
              <!-- Booking Summary -->
              <div class="card booking-card">
                <div class="card-header">
                  <h4 class="card-title">Billing Summary</h4>
                </div>
                <div class="card-body">
                  <div class="booking-summary">
                    <div class="booking-item-wrap">
                      <div>
                        <ul class="booking-date">
                          <li>Item <span>Provider Fees</span></li>
                        </ul>
                      </div>
                      <hr />
                      <ul class="booking-date">
                        <li>
                          Date <span>{{ new Date().toDateString() }}</span>
                        </li>
                      </ul>
                      <hr />
                      <ul class="booking-fee">
                        <li v-if="processingAmount !== 0">
                          Provider Fees <span>${{ providerAmount }} USD</span>
                        </li>
                        <li v-if="processingAmount === 0">
                          Flat Fees <span>${{ providerAmount }} USD</span>
                        </li>
                        <li :class="processingAmount === 0 ? 'strike' : ''">
                          Processing Fees
                          <span :class="processingAmount === 0 ? 'strike' : ''"
                            >${{ processingAmount }} USD</span
                          >
                        </li>
                      </ul>
                      <div class="booking-total">
                        <small
                          >Note: Please check the provider refund policy.</small
                        >
                        <hr />
                        <ul class="booking-total-list">
                          <li>
                            <span>Total Payable</span>
                            <span class="total-cost"
                              >${{ totalAmount }} USD</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Booking Summary -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <layout-footer></layout-footer>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, HTTP_API } from "../../constants/config";
import { mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  props: {
    prescriptionId: String,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      providerAmount: 0,
      processingAmount: 0,
      totalAmount: "",
      billingDetails: {},
    };
  },
  async mounted() {
    this.showLoader(true);
    await this.validatePrescription();
    const self = this;
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.email = this.$store.getters.user.email;
    this.mobileNumber = this.$store.getters.user.primary_phone;

    paypal
      .Buttons({
        // Sets up the transaction when a payment button is clicked
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: self.totalAmount,
                },
              },
            ],
          });
        },

        // Finalize the transaction after payer approval
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (orderData) {
            var transaction = orderData.purchase_units[0].payments.captures[0];
            self.billingDetails = {
              transactionId: transaction.id,
              status: transaction.status,
              amount: transaction.amount.value,
              currency: transaction.amount.currency_code,
              payer: orderData.payer,
              payee: orderData.purchase_units[0].payee,
              shippingInfo: orderData.purchase_units[0].shipping,
            };

            self.redirect();
          });
        },
      })
      .render("#paypal-button-container");

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    async validatePrescription() {
      try {
        this.showLoader(true);
        const res = await axios.get(
          `${BASE_URL + HTTP_API.patientValidateProviderPayment}/${
            this.prescriptionId
          }`
        );

        this.providerAmount = parseFloat(res.data.prescriptionFee);
        this.processingAmount = parseFloat(res.data.processingFee);

        this.totalAmount = Number(
          this.providerAmount + this.processingAmount
        ).toFixed(2);

        this.showLoader(false);
      } catch (e) {
        Swal.fire("Billing Error", e.response.data.message, "error").then(
          () => {
            this.$router.push("/patient/index");
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          }
        );
      }
    },
    async redirect() {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.patientConfirmProviderPayment}`,
          { ...this.billingDetails, prescriptionId: this.prescriptionId }
        );

        this.$router.push(
          `/patient/payment-success/${res.data.prescriptionNumber}`
        );
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        this.showLoader(false);
      }
    },
  },
};
</script>

<style scoped>
.strike {
  text-decoration: line-through;
}
</style>
