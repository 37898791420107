<template>
  <div class="main-wrapper">
    <layout-header></layout-header>

    <!-- Breadcrumb -->
    <div class="breadcrumb-bar">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-md-12 col-12">
            <h2 class="breadcrumb-title">Confirmation Payment</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- /Breadcrumb -->

    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-lg-8 order-2">
            <div class="card">
              <div class="card-body">
                <!-- Checkout Form -->
                <form>
                  <!-- Billing Information -->
                  <div class="info-widget">
                    <h4 class="card-title">Billing Information</h4>
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>First Name</label>
                          <input
                            class="form-control"
                            v-model="firstName"
                            type="text"
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Last Name</label>
                          <input
                            class="form-control"
                            v-model="lastName"
                            type="text"
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Email</label>
                          <input
                            class="form-control"
                            type="email"
                            v-model="email"
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group card-label">
                          <label>Mobile Number</label>
                          <input
                            class="form-control"
                            type="text"
                            v-model="mobileNumber"
                            required
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /Personal Information -->

                  <div class="payment-widget">
                    <h4 class="card-title">Payment Methods</h4>

                    <!-- Paypal Payment -->
                    <!-- <div class="payment-list">
                      <label class="payment-radio paypal-option">
                        <input
                          type="radio"
                          name="radio"
                          checked
                        >
                        <span class="checkmark"></span>
                        PayPal
                      </label>
                    </div> -->
                    <!-- /Paypal Payment -->
                    <hr />
                    <!-- Terms Accept -->
                    <!-- <div class="terms-accept">
                      <div class="custom-checkbox">
                        <input
                          type="checkbox"
                          id="terms_accept"
                        >
                        <label for="terms_accept">I have read and accept <a href="#">Terms &amp; Conditions</a></label>
                      </div>
                    </div> -->
                    <!-- /Terms Accept -->

                    <!-- Submit Section -->
                    <div class="submit-section mt-4">
                      <div id="paypal-button-container"></div>

                      <!-- <button
                        @click="submitForm"
                        class="btn btn-primary submit-btn"
                      >Confirm and Pay</button> -->
                    </div>
                    <!-- /Submit Section -->
                  </div>
                </form>
                <!-- /Checkout Form -->
              </div>
            </div>
          </div>

          <div class="col-md-5 col-lg-4 theiaStickySidebar">
            <div class="stickyside">
              <!-- Booking Summary -->
              <div class="card booking-card">
                <div class="card-header">
                  <h4 class="card-title">Billing Summary</h4>
                </div>
                <div class="card-body">
                  <div class="booking-summary">
                    <div class="booking-item-wrap">
                      <div>
                        <ul class="booking-date">
                          <li>Item <span>Prescription Service Fee</span></li>
                        </ul>
                      </div>
                      <hr />
                      <ul class="booking-date">
                        <li>
                          Date <span>{{ new Date().toDateString() }}</span>
                        </li>
                      </ul>
                      <hr />
                      <ul class="booking-fee">
                        <li>
                          Service Fee <span>${{ totalAmount }} USD</span>
                        </li>
                      </ul>
                      <div class="booking-total">
                        <small>Note: The service fee is non-refundable.</small>
                        <hr />
                        <ul class="booking-total-list">
                          <li>
                            <span>Total</span>
                            <span class="total-cost"
                              >${{ totalAmount }} USD</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Booking Summary -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <layout-footer></layout-footer>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL, HTTP_API } from "../../constants/config";
import { mapActions } from "vuex";
export default {
  props: {
    prescriptionId: String,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      totalAmount: "5",
      billingDetails: {},
    };
  },
  async mounted() {
    this.showLoader(true);
    await this.validatePrescription();
    const self = this;
    this.firstName = this.$store.getters.user.first_name;
    this.lastName = this.$store.getters.user.last_name;
    this.email = this.$store.getters.user.email;
    this.mobileNumber = this.$store.getters.user.primary_phone;

    paypal
      .Buttons({
        // Sets up the transaction when a payment button is clicked
        createOrder: function (data, actions) {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: Math.round(self.totalAmount).toFixed(2),
                },
              },
            ],
          });
        },

        // Finalize the transaction after payer approval
        onApprove: function (data, actions) {
          return actions.order.capture().then(function (orderData) {
            var transaction = orderData.purchase_units[0].payments.captures[0];
            self.billingDetails = {
              transactionId: transaction.id,
              status: transaction.status,
              amount: transaction.amount.value,
              currency: transaction.amount.currency_code,
              payer: orderData.payer,
              payee: orderData.purchase_units[0].payee,
              shippingInfo: orderData.purchase_units[0].shipping,
            };

            self.redirect();
          });
        },
      })
      .render("#paypal-button-container");

    this.showLoader(false);
  },
  methods: {
    ...mapActions(["showLoader"]),
    async validatePrescription() {
      try {
        this.showLoader(true);
        await axios.get(
          `${BASE_URL + HTTP_API.patientValidatePrescription}/${
            this.prescriptionId
          }`
        );
        this.showLoader(false);
      } catch (e) {
        alert(e.response.data.message);
        this.$router.push("/patient/index");
      }
    },
    async redirect() {
      try {
        this.showLoader(true);
        const res = await axios.post(
          `${BASE_URL + HTTP_API.patientConfirmPrescription}`,
          { ...this.billingDetails, prescriptionId: this.prescriptionId }
        );

        this.$router.push(
          `/patient/request-success/${res.data.prescriptionNumber}`
        );
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        this.showLoader(false);
      }
    },
  },
};
</script>
