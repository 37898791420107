<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row" v-if="pharmacyResults.length === 0">
        <div class="col-12">
          <div class="banner-wrapper-pharmacy">
            <div class="banner-header text-center">
              <h2>
                {{ $t("patient.changePharmacy.searchPharmacyNearYou.title") }}
              </h2>
            </div>

            <hr />

            <div class="p-2" v-if="pharmacy && pharmacy.pharmacyName">
              <div class="row">
                <div class="col-12">
                  <h4 class="mb-4">Selected Pharmacy:</h4>
                </div>
                <div class="col-8">
                  <h5>
                    {{ $t("patient.newPrescription.nameLabel") }}:
                    {{ pharmacy.pharmacyName }}
                  </h5>
                </div>
              </div>
              <h6>
                {{ $t("patient.newPrescription.phoneLabel") }}:
                {{ pharmacy.phone }}
              </h6>
              <h6>
                {{ $t("patient.newPrescription.addressLabel") }}:
                {{ getStoreAddress(pharmacy.address) }}
              </h6>
            </div>

            <div class="row mb-3">
              <div class="col-12 col-lg-6 col-md-6">
                <button
                  @click="selectPharmacy(pharmacy, false)"
                  type="button"
                  class="btn btn-primary btn-block mb-3"
                >
                  Select & Proceed
                </button>
              </div>
              <div class="col-12 col-lg-6 col-md-6">
                <button
                  type="button"
                  class="btn btn-outline-secondary btn-block"
                  @click="disableSearch = !disableSearch"
                >
                  Change Pharmacy
                </button>
              </div>
            </div>

            <hr class="mt-3" />

            <!-- Search -->
            <div class="mt-4">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <!-- PHARMACY NAME SEARCH -->
                  <div class="row">
                    <div class="col-12">
                      <small>
                        {{
                          $t(
                            "patient.changePharmacy.searchPharmacyNearYou.searchByPharmacyNameLabel"
                          )
                        }}</small
                      >
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          placeholder="Ex: Shollenberger Pharmacy"
                          v-model="searchName"
                          :disabled="disableSearch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <!-- CITY SEARCH -->
                  <div class="row">
                    <div class="col-12">
                      <small>{{
                        $t(
                          "patient.changePharmacy.searchPharmacyNearYou.searchByCityLabel"
                        )
                      }}</small>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          placeholder="Ex: Atlanta, New York"
                          v-model="searchCity"
                          :disabled="disableSearch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <!-- STATE SEARCH -->
                  <div class="row">
                    <div class="col-12">
                      <small>{{
                        $t(
                          "patient.changePharmacy.searchPharmacyNearYou.searchByStateCodeLabel"
                        )
                      }}</small>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-3">
                        <input
                          type="text"
                          class="form-control form-control-xs"
                          placeholder="Ex: GA, NY"
                          v-model="searchState"
                          :disabled="disableSearch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <!-- ZIP CODE SEARCH -->
                  <div class="row">
                    <div class="col-12">
                      <small>{{
                        $t(
                          "patient.changePharmacy.searchPharmacyNearYou.searchByZipCodeLabel"
                        )
                      }}</small>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-3">
                        <input
                          type="number"
                          class="form-control form-control-xs"
                          placeholder="Ex: 60601, 85001"
                          v-model="searchZipCode"
                          :disabled="disableSearch"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12" v-show="specialtyFilter">
                <small>{{
                  $t(
                    "patient.changePharmacy.searchPharmacyNearYou.selectSpecialtyLabel"
                  )
                }}</small>
              </div>
              <div class="col-12" v-show="specialtyFilter">
                <div class="form-group mb-3">
                  <select
                    v-model="searchSpecialty"
                    class="form-control form-control-xs"
                    :disabled="disableSearch"
                  >
                    <option value="2048" selected>EPCS</option>
                    <option value="64">24 Hour Pharmacy</option>
                    <option value="32">Long Term Care</option>
                    <option value="1">Mail Order</option>
                    <option value="8">Retail</option>
                    <option value="16">Specialty Pharmacy</option>
                  </select>
                  <span class="form-text text-muted">
                    {{
                      $t(
                        "patient.changePharmacy.searchPharmacyNearYou.ePCSMailOrderRetail24HourPharmacy"
                      )
                    }}</span
                  >
                </div>
              </div>
              <div class="col-12 p-4 text-right">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="specialtyFilter"
                    :disabled="disableSearch"
                  />
                  <label class="form-check-label mt-1" for="flexCheckDefault">
                    {{
                      $t(
                        "patient.changePharmacy.searchPharmacyNearYou.filterBySpecialtyLabel"
                      )
                    }}
                  </label>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-12">
                <button
                  @click="startSearching"
                  type="button"
                  class="btn btn-primary mt-0 btn-block"
                  :disabled="disableSearch"
                >
                  <i class="fas fa-search"></i>
                  <span>{{
                    $t("patient.changePharmacy.searchPharmacyNearYou.btn")
                  }}</span>
                </button>
              </div>
            </div>
            <!-- /Search -->
          </div>
        </div>
      </div>

      <div v-else>
        <div class="row">
          <div class="col-md-10">
            <div class="banner-header">
              <h3>
                {{
                  $t(
                    "patient.changePharmacy.greatWeFoundPharmaciesNearYou.title1"
                  )
                }}
                {{ filteredPharmacyResults.length
                }}{{
                  $t(
                    "patient.changePharmacy.greatWeFoundPharmaciesNearYou.title2"
                  )
                }}
              </h3>
            </div>
          </div>
          <div class="col-md-2">
            <button
              @click="restartSearch"
              class="btn btn-sm btn-outline-info btn-block"
            >
              {{
                $t("patient.changePharmacy.greatWeFoundPharmaciesNearYou.btn1")
              }}
            </button>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col-md-12 text-center">
            <strong class="m-3">
              {{
                $t(
                  "patient.changePharmacy.greatWeFoundPharmaciesNearYou.filterBylabel"
                )
              }}:</strong
            >
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="specialtyFilter"
                v-model="specialtyCheckFilter"
              />
              <label class="form-check-label" for="specialtyFilter">
                {{
                  $t(
                    "patient.changePharmacy.greatWeFoundPharmaciesNearYou.specialtyLabel"
                  )
                }}</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="mailOrderFilter"
                v-model="mailOrderFilter"
              />
              <label class="form-check-label" for="mailOrderFilter">{{
                $t(
                  "patient.changePharmacy.greatWeFoundPharmaciesNearYou.mailOrder"
                )
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="retailFilter"
                v-model="retailFilter"
              />
              <label class="form-check-label" for="retailFilter">{{
                $t(
                  "patient.changePharmacy.greatWeFoundPharmaciesNearYou.retail"
                )
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="epcsFilter"
                v-model="epcsFilter"
              />
              <label class="form-check-label" for="epcsFilter">{{
                $t("patient.changePharmacy.greatWeFoundPharmaciesNearYou.epcs")
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="24HourFilter"
                v-model="twentyFourHourFilter"
              />
              <label class="form-check-label" for="24HourFilter">{{
                $t("patient.changePharmacy.greatWeFoundPharmaciesNearYou.hour")
              }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="longTermFilter"
                v-model="longTermFilter"
              />
              <label class="form-check-label" for="longTermFilter">{{
                $t(
                  "patient.changePharmacy.greatWeFoundPharmaciesNearYou.longTermCare"
                )
              }}</label>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="order-2 col-md-12 order-md-2">
            <!-- Doctor Widget -->
            <div
              class="card"
              v-for="(pharmacy, index) in filteredPharmacyResults"
              :key="index"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <div class="doctor-widget">
                      <div class="doc-info-left">
                        <div class="doctor-img1">
                          <img
                            :src="getImage('none')"
                            width="100"
                            class="img-fluid"
                            alt="Pharmacy Image"
                          />
                        </div>
                        <div class="doc-info-cont">
                          <h4 class="doc-name mb-2">
                            {{ pharmacy.StoreName }} - {{ pharmacy.PharmacyId }}
                          </h4>
                          <div class="rating mb-2 mapgridrating1">
                            <span class="d-inline-block average-rating">{{
                              getStoreAddressAfterSearch(pharmacy)
                            }}</span>
                          </div>
                          <div class="clinic-details">
                            <div class="clini-infos">
                              <p class="doc-location mb-2">
                                <i class="fas fa-phone-volume mr-1"></i>
                                {{
                                  $t(
                                    "patient.changePharmacy.greatWeFoundPharmaciesNearYou.phone"
                                  )
                                }}
                                :{{ pharmacy.PrimaryPhone }}
                                <i class="fas fa-fax ml-3 mr-1"></i>
                                {{
                                  $t(
                                    "patient.changePharmacy.greatWeFoundPharmaciesNearYou.fax"
                                  )
                                }}:
                                {{ pharmacy.PrimaryFax }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-md-3">
                    <h5>
                      {{
                        $t(
                          "patient.changePharmacy.greatWeFoundPharmaciesNearYou.supportedType"
                        )
                      }}:
                    </h5>
                    <span
                      v-if="!pharmacy.PharmacySpecialties"
                      class="badge badge-dark mr-2"
                    >
                      {{
                        $t(
                          "patient.changePharmacy.greatWeFoundPharmaciesNearYou.noneBadge"
                        )
                      }}</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('Retail') >= 0
                      "
                      class="badge badge-info mr-2"
                      >{{
                        $t(
                          "patient.changePharmacy.greatWeFoundPharmaciesNearYou.retailBadge"
                        )
                      }}</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('Mail Order') >= 0
                      "
                      class="badge badge-info mr-2"
                      >{{
                        $t(
                          "patient.changePharmacy.greatWeFoundPharmaciesNearYou.mailOrderBadge"
                        )
                      }}</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf('EPCS') >= 0
                      "
                      class="badge badge-info mr-2"
                      >{{
                        $t(
                          "patient.changePharmacy.greatWeFoundPharmaciesNearYou.epcs"
                        )
                      }}</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf(
                          'Specialty Pharmacy'
                        ) >= 0
                      "
                      class="badge badge-info mr-2"
                      >{{
                        $t(
                          "patient.changePharmacy.greatWeFoundPharmaciesNearYou.specialityBadge"
                        )
                      }}</span
                    >
                    <span
                      v-if="
                        pharmacy.PharmacySpecialties &&
                        pharmacy.PharmacySpecialties.indexOf(
                          'Long-Term Care Pharmacy'
                        ) >= 0
                      "
                      class="badge badge-info mr-2"
                      >{{
                        $t(
                          "patient.changePharmacy.greatWeFoundPharmaciesNearYou.longTermCareBadge"
                        )
                      }}</span
                    >
                  </div>
                  <div class="col-12 col-md-3">
                    <div class="clinic-booking">
                      <a
                        href="javascript:void();"
                        class="apt-btn"
                        @click="selectPharmacy(pharmacy, true)"
                        >{{
                          $t(
                            "patient.changePharmacy.greatWeFoundPharmaciesNearYou.selectPharmacybtn"
                          )
                        }}</a
                      >
                      <a
                        :href="`https://www.google.com/maps/@${pharmacy.Latitude},${pharmacy.Longitude},15z`"
                        target="blank"
                        class="view-pro-btn"
                        >{{
                          $t(
                            "patient.changePharmacy.greatWeFoundPharmaciesNearYou.seeOnGooglebtn"
                          )
                        }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Doctor Widget -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import commonMixin from "../../mixins/common";
import Swal from "sweetalert2";
const images = require.context(
  "../../assets/img/stores/",
  false,
  /\.png$|\.jpg$/
);

export default {
  mixins: [commonMixin],
  data() {
    return {
      searchName: "",
      searchCity: "",
      searchState: "",
      searchZipCode: "",
      searchSpecialty: "",
      specialtyFilter: false,
      pharmacyResults: [],
      filteredPharmacyResults: [],
      searchOption: "name",
      specialtyCheckFilter: true,
      epcsFilter: true,
      mailOrderFilter: true,
      twentyFourHourFilter: true,
      longTermFilter: true,
      retailFilter: true,
      pharmacy: null,
      disableSearch: true,
    };
  },
  mounted() {
    this.searchCity = this.$store.getters.user.city;
    this.searchState = this.$store.getters.user.state;
    this.searchZipCode = this.$store.getters.user.zip_code;
    this.pharmacy = this.$store.getters.user.pharmacy;
  },
  watch: {
    specialtyFilter: function () {
      if (!this.specialtyFilter) this.searchSpecialty = null;
    },
    specialtyCheckFilter: function () {
      this.filterData();
    },
    epcsFilter: function () {
      this.filterData();
    },
    mailOrderFilter: function () {
      this.filterData();
    },
    twentyFourHourFilter: function () {
      this.filterData();
    },
    longTermFilter: function () {
      this.filterData();
    },
    retailFilter: function () {
      this.filterData();
    },
  },
  methods: {
    ...mapActions(["showLoader", "showAlert"]),
    filterData() {
      const retails = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties && p.PharmacySpecialties.indexOf("Retail") > -1
      );
      const specialty = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Specialty Pharmacy") > -1
      );
      const mailOrders = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Mail Order") > -1
      );
      const epcs = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties && p.PharmacySpecialties.indexOf("EPCS") > -1
      );
      const longTerm = this.pharmacyResults.filter(
        (p) =>
          p.PharmacySpecialties &&
          p.PharmacySpecialties.indexOf("Long-Term Care Pharmacy") > -1
      );

      let filtered = [];
      if (this.specialtyCheckFilter) filtered = filtered.concat(specialty);
      if (this.retailFilter) filtered = filtered.concat(retails);
      if (this.mailOrderFilter) filtered = filtered.concat(mailOrders);
      if (this.epcsFilter) filtered = filtered.concat(epcs);
      if (this.longTermFilter) filtered = filtered.concat(longTerm);

      filtered = filtered.concat(
        this.pharmacyResults.filter((p) => !p.PharmacySpecialties)
      );

      this.filteredPharmacyResults = filtered.filter(
        (item, pos) => filtered.indexOf(item) === pos
      );
    },
    restartSearch() {
      this.searchName = "";
      this.searchCity = this.$store.getters.user.city;
      this.searchState = this.$store.getters.user.state;
      this.searchZipCode = this.$store.getters.user.zip_code;
      this.pharmacyResults = [];
      this.filteredPharmacyResults = [];
    },
    async startSearching() {
      try {
        if (
          String(this.searchZipCode).trim().length === 0 &&
          String(this.searchCity).trim().length === 0 &&
          String(this.searchState).trim().length === 0 &&
          String(this.searchName).trim().length === 0 &&
          String(this.searchSpecialty).trim().length === 0
        ) {
          Swal.fire(
            "Oops!",
            "Please enter a name, city, state, zip or specialty to find the pharmacies",
            "error"
          );
          return;
        }

        this.showLoader(true);

        let query = "?";
        if (String(this.searchName).trim().length > 0)
          query += `name=${this.searchName}&`;
        if (String(this.searchCity).trim().length > 0)
          query += `city=${this.searchCity}&`;
        if (String(this.searchState).trim().length > 0)
          query += `state=${this.searchState}&`;
        if (String(this.searchZipCode).trim().length > 0)
          query += `zip=${this.searchZipCode}&`;
        if (String(this.searchSpecialty).trim().length > 0)
          query += `specialty[0]=${this.searchSpecialty}&`;

        query = query.slice(0, -1);

        this.pharmacyResults = await this.searchPharmacy(query);

        if (this.pharmacyResults.length === 0) {
          Swal.fire(
            "No Pharmacies Found",
            "We did not find any pharmacies in your search criteria.",
            "warning"
          );
        }

        this.filteredPharmacyResults = this.pharmacyResults;
        this.showLoader(false);
      } catch (e) {
        console.error(e);
        Swal.fire("Ooops!", e.response.data.message, "error");
        this.showLoader(false);
      }
    },

    selectPharmacy(pharmacyDetails, formatData) {
      let payload = pharmacyDetails;
      if (formatData) {
        payload = {
          pharmacyId: pharmacyDetails.PharmacyId,
          pharmacyName: pharmacyDetails.StoreName,
          address: {
            addressLine1: pharmacyDetails.Address1,
            addressLine2: pharmacyDetails.Address2,
            state: pharmacyDetails.City,
            city: pharmacyDetails.State,
            zipCode: pharmacyDetails.ZipCode,
          },
          phone: pharmacyDetails.PrimaryPhone,
        };
      }

      this.$emit("select", JSON.stringify(payload));
    },

    getStoreAddressAfterSearch(addressObject) {
      return `${addressObject.Address1}, ${addressObject.Address2}, ${addressObject.City}, ${addressObject.State} - ${addressObject.ZipCode}`;
    },

    getStoreAddress(addressObject) {
      return `${addressObject.addressLine1}, ${addressObject.addressLine2}, ${addressObject.city}, ${addressObject.state} - ${addressObject.zipCode}`;
    },

    getImage(storeBrand) {
      switch (String(storeBrand).toLowerCase()) {
        case "walgreens":
          return images("./walgreens.jpg").default;

        default:
          return images("./notavailable.png").default;
      }
    },
  },
};
</script>
<style scoped>
.banner-wrapper-pharmacy {
  margin: 0 auto;
  max-width: 620px;
  width: 100%;
}

.section-search {
  height: 780px;
}

.pharmacy-search-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.pharmacy-search-box .form-control {
  border: 1px solid #ccc;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.075);
  border-radius: 5px;
  padding-left: 35px;
}

.pharmacy-search-box .search-location {
  -ms-flex: 0 0 280px;
  flex: 0 0 280px;
  margin-right: 12px;
  position: relative;
  width: 280px;
}

.pharmacy-search-box .search-filter {
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
  margin-right: 12px;
  position: relative;
  width: 180px;
}

.pharmacy-search-box .search-info {
  -ms-flex: 0 0 400px;
  flex: 0 0 400px;
  margin-right: 12px;
  position: relative;
  width: 400px;
}

.search-info .form-control {
  background: #fff url(../../assets/img/search.png) no-repeat 10px center;
}

.search-filter .form-control {
  background: #fff url(../../assets/img/search.png) no-repeat 10px center;
}

.pharmacy-search-box .search-btn {
  width: 46px;
  -ms-flex: 0 0 46px;
  flex: 0 0 46px;
  height: 46px;
}

.pharmacy-search-box .search-btn span {
  display: none;
  font-weight: 500;
}

.pharmacy-search-box .form-text {
  color: #757575;
  font-size: 13px;
}

@media only screen and (max-width: 600px) {
  .pharmacy-search-box .search-info {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }

  .pharmacy-search-box .search-filter {
    -ms-flex: 0 0 270px;
    flex: 0 0 270px;
    margin: auto;
    position: relative;
    width: 270px;
  }
}
</style>
