<template>
  <div id="app">
    <!-- Generic loader -->
    <loader></loader>

    <!-- Generic alert box -->
    <alert-modal />

    <!-- Incoming call modal -->
    <div
      class="modal fade call-modal"
      data-backdrop="static"
      data-keyboard="false"
      id="incoming_call"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div
            class="modal-body"
            v-if="incomingCallDetails.prescriptionId"
            style="padding: 20px"
          >
            <IncomingCall
              :prescriptionId="incomingCallDetails.prescriptionId"
              :userImage="incomingCallDetails.userImage"
              :userName="incomingCallDetails.userName"
              :requestId="incomingCallDetails.requestId"
              :callId="incomingCallDetails.callId"
              :callType="incomingCallDetails.callType"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Router view -->
    <router-view :key="$route.fullPath"> </router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import commonMixin from "./mixins/common";
import IncomingCall from "./components/IncomingCall.vue";

export default {
  name: "App",
  mixins: [commonMixin],
  components: {
    IncomingCall,
  },
  data() {
    return {
      incomingCallDetails: {},
      coolback: false,
    };
  },
  methods: {
    ...mapActions(["validateSession", "getUserDetails"]),
  },
  async mounted() {
    // console.log(this.$socket)
    this.sockets.subscribe("MEET:INCOMING_CALL_REQUEST", (data) => {
      this.incomingCallDetails = data;
      $("#incoming_call").modal("show");
    });

    // try {
    //   // Validate local token first
    //   let token = localStorage.getItem("user-token");

    //   if (token) {
    //     await this.validateSession(token);
    //     const decodeToken = this.parseJwt(token);
    //     if (decodeToken) {
    //       const user = await this.getUserDetails(decodeToken.sub);
    //       switch (user.role) {
    //         case "patient":

    //           this.$socket.emit("REQUEST", {
    //             command: "SET:USER_ID",
    //             userId: this.$store.getters.user.id,
    //           });

    //           if (!user.is_registration_complete) {
    //             this.$router.push(
    //               `/patient/step-${user.current_registration_step}`
    //             );
    //           }
    //           break;
    //         case "provider":
    //           this.$socket.emit("REQUEST", {
    //             command: "SET:USER_ID",
    //             userId: this.$store.getters.user.id,
    //           });

    //           if (!user.is_registration_complete) {
    //             this.$router.push(
    //               `/provider/step-${user.current_registration_step}`
    //             );
    //           }

    //           break;
    //       }
    //     }
    //   }
    // } catch (e) {
    //   localStorage.removeItem("user-token");
    //   this.$router.push("/");
    // }

    try {
      setTimeout(async () => {
        // REACT NATIVE HANDLERS
        if (window.isNativeApp) {
          this.$router.push("/login");
          this.$store.dispatch("showLoader", true);
          // Send connection message
          ReactNativeWebView.postMessage(
            JSON.stringify({ command: "CONNECTION", data: "CONNECTED" })
          );
          // Response Handlers
          window.postMessage = async (message) => {
            switch (message.command) {
              case "GO_BACK":
                if (
                  !window.history.state.back ||
                  window.history.state.back === "/" ||
                  String(window.history.state.back).includes("login") ||
                  String(this.$route.path).includes("login") ||
                  String(this.$route.path).includes("patient-dashboard")
                ) {
                  ReactNativeWebView.postMessage(
                    JSON.stringify({ command: "GO_BACK_EMPTY", data: "" })
                  );
                } else {
                  if (!this.coolback) {
                    window.history.go(-1);
                    this.coolback = true;
                    setTimeout(() => {
                      this.coolback = false;
                    }, 500);
                  } else {
                    this.coolback = false;
                    ReactNativeWebView.postMessage(
                      JSON.stringify({ command: "GO_BACK_EMPTY", data: "" })
                    );
                  }
                }
                break;
              case "SET_DEVICE_INFO":
                // Redirect if not actual device
                if (!message.data.isDevice)
                  window.location = "https://stepaheadhealth.com";
                // Store device details
                this.$store.dispatch("setDevice", {
                  isDevice: message.data.isDevice,
                  brand: message.data.brand,
                  deviceName: message.data.deviceName,
                  os: message.data.os,
                  osVersion: message.data.osVersion,
                  manufacturer: message.data.manufacturer,
                  deviceToken: message.data.deviceToken,
                  expoToken: message.data.expoToken,
                });
                break;
              case "DEVICE_INFO":
                // Redirect if not actual device
                if (!message.data.isDevice)
                  window.location = "https://stepaheadhealth.com";
                // Store device details
                this.$store.dispatch("setDevice", {
                  isDevice: message.data.isDevice,
                  brand: message.data.brand,
                  deviceName: message.data.deviceName,
                  os: message.data.os,
                  osVersion: message.data.osVersion,
                  manufacturer: message.data.manufacturer,
                  deviceToken: message.data.deviceToken,
                  expoToken: message.data.expoToken,
                });
                // Validate device token
                try {
                  if (message.data.authToken) {
                    try {
                      // Validate session
                      this.$store.dispatch(
                        "setAuthorization",
                        message.data.authToken
                      );
                      await this.$store.dispatch(
                        "validateSession",
                        message.data.authToken
                      );
                      const decodeToken = this.parseJwt(message.data.authToken);
                      if (decodeToken) {
                        const user = await this.$store.dispatch(
                          "getUserDetails",
                          decodeToken.sub
                        );
                        // Update login device
                        this.$store.dispatch("validateLoginDevice", {
                          userId: user.id,
                          isDevice: message.data.isDevice,
                          brand: message.data.brand,
                          deviceName: message.data.deviceName,
                          os: message.data.os,
                          osVersion: message.data.osVersion,
                          manufacturer: message.data.manufacturer,
                          deviceToken: message.data.deviceToken,
                          expoToken: message.data.expoToken,
                        });
                        switch (user.role) {
                          case "patient":
                            if (!user.is_registration_complete) {
                              this.$router.push(
                                `/patient/step-${user.current_registration_step}`
                              );
                              // setTimeout(() => {
                              //   this.$store.dispatch("showLoader", false);
                              // }, 1000);
                              return;
                            }
                            this.$router.push("/patient/index");
                            // setTimeout(() => {
                            //   this.$store.dispatch("showLoader", false);
                            // }, 1000);
                            break;
                          case "provider":
                            if (!user.is_registration_complete) {
                              this.$router.push(
                                `/provider/step-${user.current_registration_step}`
                              );
                              // setTimeout(() => {
                              //   this.$store.dispatch("showLoader", false);
                              // }, 1000);
                              return;
                            }
                            this.$router.push("/provider/index");
                            // setTimeout(() => {
                            //   this.$store.dispatch("showLoader", false);
                            // }, 1000);
                            break;
                          default:
                            this.$store.dispatch("showLoader", false);
                            Swal.fire(
                              "Ooops!",
                              "You are not authorized to login",
                              "error"
                            );
                            try {
                              await this.$store.dispatch("logOut", {
                                id: this.$store.getters.user.id,
                                deviceToken: this.$store.getters.device
                                  ? this.$store.getters.device.deviceToken
                                  : "",
                              });
                              this.$router.push("/login");
                            } catch (e) {
                              await this.$store.dispatch("logOut", {
                                id: this.$store.getters.user.id,
                                deviceToken: this.$store.getters.device
                                  ? this.$store.getters.device.deviceToken
                                  : "",
                              });
                              this.$router.push("/login");
                            }
                            ReactNativeWebView.postMessage(
                              JSON.stringify({ command: "FLUSH_TOKEN" })
                            );
                        }
                      }
                    } catch (e) {
                      Swal.fire({
                        icon: "error",
                        title: `Ooops!`,
                        text: e,
                        showConfirmButton: false,
                        timer: 2000,
                      });
                      this.$store.dispatch("showLoader", false);
                      this.logout();
                      this.$router.push("/login");
                      ReactNativeWebView.postMessage(
                        JSON.stringify({ command: "FLUSH_TOKEN" })
                      );
                    }
                  } else {
                    this.$store.dispatch("showLoader", false);
                    this.logout();
                    this.$router.push("/login");
                  }
                } catch (e) {
                  Swal.fire({
                    icon: "error",
                    title: `Ooops!`,
                    text: e,
                    showConfirmButton: false,
                    timer: 2000,
                  });
                  this.$store.dispatch("showLoader", false);
                  this.$router.push("/login");
                }
                break;
            }
          };
        }

        // NORMAL WEB BROWSER
        else {
          // Validate local token first
          let token = localStorage.getItem("user-token");
          if (token) {
            await this.validateSession(token);
            const decodeToken = this.parseJwt(token);
            if (decodeToken) {
              const user = await this.getUserDetails(decodeToken.sub);
              switch (user.role) {
                case "patient":
                  this.$socket.emit("REQUEST", {
                    command: "SET:USER_ID",
                    userId: this.$store.getters.user.id,
                  });

                  if (!user.is_registration_complete) {
                    this.$router.push(
                      `/patient/step-${user.current_registration_step}`
                    );
                  }
                  break;
                case "provider":
                  this.$socket.emit("REQUEST", {
                    command: "SET:USER_ID",
                    userId: this.$store.getters.user.id,
                  });

                  if (!user.is_registration_complete) {
                    this.$router.push(
                      `/provider/step-${user.current_registration_step}`
                    );
                  }

                  break;
              }
            }
          }
        }
      }, 500);
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: `Ooops!`,
        text: e,
        showConfirmButton: false,
        timer: 2000,
      });
      this.$store.dispatch("showLoader", false);
      this.logout();
      this.$router.push("/login");
    }
  },
};
</script>
<style>
body {
  -moz-transform: scale(0.9, 0.9);
  /* Moz-browsers */
  zoom: 0.9;
  /* Other non-webkit browsers */
  zoom: 90%;
  /* Webkit browsers */
}

.modal-backdrop.show {
  width: 100%;
  height: 100%;
}
</style>
